<script setup lang="ts">
import { useAuth } from '~/stores/authentication'
import { useModal } from '~/stores/modal'
import { Models } from '~/types/models'

const { CREATE_BOOKMARK, DELETE_BOOKMARK } = useProfileService()

const AuthStore = useAuth()

const props = defineProps<{
    post: Models.ArtistResource | Models.CollectionResource
}>()

const disabled = ref(false)

const createBookmark = async (newBookmark: boolean) => {
    disabled.value = true
    if (AuthStore.isAuth && AuthStore.userProfile) {
        const body = {
            bookmarkable_id: props.post.id,
            bookmarkable_type:
                props.post.type === 'collection'
                    ? 'collection'
                    : props.post.type === 'exhibit'
                    ? 'exhibit'
                    : props.post.type === 'special_project'
                    ? 'specialProject'
                    : 'artist',
        } as {
            bookmarkable_id: number
            bookmarkable_type: 'artist' | 'collection' | 'exhibit' | 'specialProject'
        }

        try {
            if (newBookmark) {
                await CREATE_BOOKMARK(body)
            } else if (props.post.id) {
                await DELETE_BOOKMARK(body)
            }
            props.post.bookmarked = newBookmark
        } catch (e) {
            console.log('Error on save post: ', { e })
        }
    } else {
        const ModalStore = useModal()

        ModalStore.setModal({
            name: 'ModalDefault',
            info: {
                text: 'Necesitas iniciar sesión o registrarte para guardar tus favoritos.',
                continue: {
                    handler: () => {
                        navigateTo('/login')
                    },
                    text: 'Iniciar sesión',
                },
                cancel: {
                    text: 'Seguir sin guardar',
                    handler: () => null,
                },
            },
        })
    }
    disabled.value = false
}
</script>

<template>
    <button @click="() => createBookmark(!post.bookmarked)" class="bookmark" :disabled="disabled">
        <UnoIcon v-if="post.bookmarked" class="i-ri:bookmark-fill bg-cover" style="background-image: var(--un-icon);" />
        <UnoIcon v-else class="i-ri:bookmark-line bg-cover" style="background-image: var(--un-icon);" />
    </button>
</template>

<style lang="postcss" scoped>
.bookmark {
    @apply px-1 pt-1 w-fit h-fit disabled:opacity-50;
}
</style>
